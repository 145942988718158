'use client';
import React, { useContext, useEffect, createContext, useRef } from 'react';
import { usePathname, useSearchParams } from 'solito/navigation';

export type RouteHistory = string[];

export const RouteHistoryContext = createContext<RouteHistory | undefined>(undefined);
const MAX_HISTORY_LENGTH = 20;

export const RouteHistoryProvider = ({ children }: { children: React.ReactNode }) => {
  const routeHistory = useRef<RouteHistory>([]);
  const routeHistoryContext: RouteHistory | undefined = useContext(RouteHistoryContext);
  const pathName = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (!pathName || !searchParams) {
      return;
    }

    const prevRouteHistory = routeHistory.current;
    const searchString = Array.from(searchParams.entries())
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
    const fullSearchString = searchString ? `?${searchString}` : '';
    const currentUrl = pathName + fullSearchString;

    if (currentUrl === prevRouteHistory.at(-1)) {
      return;
    }
    if (prevRouteHistory.length >= MAX_HISTORY_LENGTH) {
      prevRouteHistory.shift();
    }
    routeHistory.current = [...prevRouteHistory, currentUrl];
  }, [pathName, searchParams]);

  if (routeHistoryContext !== undefined) {
    throw new Error('There can only be one RouteHistoryContext in the components tree');
  }

  return <RouteHistoryContext.Provider value={routeHistory.current}>{children}</RouteHistoryContext.Provider>;
};
